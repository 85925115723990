import React from 'react';
import './styles.scss';
import VideoBackground from './../../Videos/services.mp4';
import { Controller, Scene } from 'react-scrollmagic';


export default class Services extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
        VideoUrl: VideoBackground,
        pip: false,
        playing: true,
        controls: false,
        light: false,
        played: 0.20,
        loaded: 0.20,
        loop: true,
        wrapper: "div",
        imageStatus: "loading",
        post:"",
        theposition:""
      }
  }
 
  componentDidMount() {
    window.scrollTo(0, 0)
  }


    render() {
        return (
            <div className="servicesPageContainer">
              <div className="DarkMaskCover" ></div>
              <video src={VideoBackground} style={{objectFit: 'cover',height:"100%"}} muted loop autoPlay playsInline type="video/mp4" > </video>

                <Controller >
                  <Scene duration={520} classToggle="ServiceSection1Active" triggerElement="#Section1" >
                    <div id="Section1"className="ServiceSection1">
                    <h2>CHOOSING THE RIGHT FIT</h2>
                    <p>Our mission is to capture the moments in a beautiful, cinematic and visually stunning way. We enjoy getting to know all of our couples and discuss how to personalize every wedding video and stunning photos of your big day! 
    All the days spent planning and all the hardworking will live forever in the photos and videos, and there are no second chances to get this opportunity to cherish your memories. 
    Being in the business since 2007, one of the longest in Southern Ontario, we have been part of so many couple’s big day. 
    With this epxerience we have developed some tips and suggestions that can help you when selecting your photo and video team to make the right decision and also show you where Diamond Digital Production stands out.</p>
                    </div>
                  </Scene>
                </Controller>

                <Controller >
                  <Scene duration={400} classToggle="ServiceSection2Active" triggerElement="#Section2"  >
                    <div id="Section2" className="ServiceSection2">
                      <h2>Simply send us a quick message about your wedding, and we will get back to you with a custom quote based on your wedding needs </h2>
                      <p>we promise you two things will always be true. One, everyone is treated equally - our rates are calculated the same way for a six hour wedding as a 26 hour wedding. Two, everything is customizable - we won't force an album into your package, but you are welcome to include one if you like.</p>
                    </div>
                  </Scene>
                </Controller>

                <Controller >
                  <Scene duration={550} classToggle="ServiceSection3Active" triggerElement="#Section3" >
                    <div id="Section3" className="ServiceSection3">
                        <h2>The following is standard on all of ourwedding photography packages.</h2>
                      <ul>
                        <li>Professional cameras as well as backup cameras </li>
                        <li>Professional lenses</li>
                        <li>Professional lighting equipment</li>
                        <li>Sneak preview images on Facebook in two weeks</li>
                        <li>All images are edited and delivered as high resolution digital files ready for print, no watermark, no copy restrictions.</li>
                      </ul>
                      <h3>Optional additions</h3>
                      <ul>
                        <li>Full selection of premium prints, albums and guest books.</li>
                        <li>Albums are handmade with genuine Italian leather</li>
                        <li>Engagement / pre-wedding photo session</li>                 
                      </ul>
                    </div>
                  </Scene>
                </Controller>

                <Controller >
                  <Scene duration={550} classToggle="ServiceSection4Active" triggerElement="#Section4" >
                    <div id="Section4" className="ServiceSection4">
                      <h2>The following come standard on all of our wedding videography packages.</h2>
                      <ul>
                        <li>One tor more highly experienced wedding cinematographers.</li>
                        <li>Two to Three super-35mm film equivalent cameras for stunning, cinema level quality.</li>
                        <li>Completely manual colour grading process.</li>
                        <li>Using high speed cameras for dramatic, real slow motion shots.</li>
                        <li>Professional camera stabilizer operator for super smooth moving shots Professional microphone and sound recording.</li>
                      </ul>
                      <h3>Optional additions</h3>
                      <ul>
                        <li>A 12ft crane for those floor to ceiling elevation shots that are frequently seen in movies.</li>
                        <li>Broadcast quality wireless microphone and audio devices to capture every heart-felt moment.</li>
                        <li>Love Story.</li> 
                        <li>Aerial Drone Shots.</li> 
                        <li>Live streaming internationally.</li>                  
                      </ul>
                  </div>
                  </Scene>
                </Controller>

                <Controller >
                  <Scene duration={1600} classToggle="ArrowsActive" triggerElement="#Section1" >
                  <div className="Arrows">
                   <div className="arrow arrow-1"></div>
                   <div className="arrow arrow-2"></div>
                  </div>
                  </Scene>
                </Controller>


            </div>
        );
      }
}