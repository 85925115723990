import React from 'react';
import BackgroundVideo from '../../components/BackgroundVideo';
import GridContainerBackground from '../../components/GridContainerBackground';

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.ToPhotos= this.ToPhotos.bind(this);

    }
ToPhotos() {
    this.props.toPhotos();
}

    render() {
        return (
            <div>
                <BackgroundVideo />
                <GridContainerBackground 
                toPhotos={this.props.toPhotos}
                toPhotos2={this.props.toPhotos2}
                toVideos={this.props.toVideos}
                openContacts={this.props.openContacts}
                outContacts={this.props.outContacts}
                toContacts={this.props.toContacts}
                contactsState={this.props.contactsState}
                />
            </div>
        );
      }
}