import React from 'react';
//import axios from 'axios';
import results from './results'
import emailjs from "emailjs-com"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

export default class ContactsPage extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      form: {
        personalInfo: {
          name: '',
          age: '',
          date: '',
          email: '',
          location: '',
          phone:''
        },
        hours: {
          sixOrLessHours: false,
          eightHours: false,
          tenHours: false,
          twelveOrMoreHours: false
        },
        service: {
          photography: false,
          videography: false,
          photoBooth: false
        },
        budget: {
          underThree: false,
          threeToSix: false,
          sixToTen: false,
          overTen: false,
          multiDayWedding: false
        },
        message: ""
      },
      mailSent: false,
      error: null
    };
    this.form = this.state;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeHours = this.handleChangeHours.bind(this);
    this.handleChangeService = this.handleChangeService.bind(this);
    this.handleChangeBudget = this.handleChangeBudget.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
  }
   
  handleChange = value => event => {
    this.setState({
      form: {
        ...this.state.form,
        personalInfo: {
          ...this.state.form.personalInfo,
          [value]: event.target.value
        }
      }
    });
  };
  handleChangeHours = value => event => {
    this.setState({
      form: {
        ...this.state.form,
        hours: {
          ...this.state.form.hours,
          [value]: event.target.checked
        }
      }
    });
  };

  handleChangeService = value => event => {
    this.setState({
      form: {
        ...this.state.form,
        service: {
          ...this.state.form.service,
          [value]: event.target.checked
        }
      }
    });
  };

  handleChangeBudget = value => event => {
    this.setState({
      form: {
        ...this.state.form,
        budget: {
          ...this.state.form.budget,
          [value]: event.target.checked
        }
      }
    });
  };

  handleChangeMessage = value => event => {
    this.setState({
      form: {
        ...this.state.form,
        [value]: event.target.value
      }
    });
  };

  handleClickOpen = () => {
    //this.setState({ open: true });
    this.props.toContacts();
  };

  handleClose = e => {
    this.setState({ open: false });

    results.post("/contacts.json",
        JSON.stringify(this.state.form),
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        this.setState({
          mailSent: response.data.sent
        });
      })
      .catch(error => {
        this.setState({ error: error.message }); 
      });
    this.props.outContacts();

    //reset all the fields
    this.setState(this.form);
    document.getElementById('form').reset();
  };
 
  sendEmail = e => {
    e.preventDefault();
    let Hours= "" ; 
    let Service ="" ; 
    let Budget = "" ; 
    if (this.state.form.hours.sixOrLessHours)
    Hours+="6 hours or less" ; 
    if (this.state.form.hours.eightHours)
    Hours+=" - 8 Hours" ; 
    if (this.state.form.hours.tenHours)
    Hours+=" - 10 Hours" ; 
    if ( this.state.form.hours.twelveOrMoreHours)
    Hours+=" - 12 Hours or more.";
    if (this.state.form.service.photography)
    Service+="Photogarphy ";
    if (this.state.form.service.videography)
    Service+=" -Videography / cinematography " ;
    if (this.state.form.service.photoBooth)
    Service+=" -Photo Booth" ; 
    if(this.state.form.budget.underThree)
    Budget+="Under 3k " ; 
    if(this.state.form.budget.threeToSix)
    Budget+="- 3k-6k" ; 
    if(this.state.form.budget.sixToTen)
    Budget+=" - 6k-10k" ; 
    if(this.state.form.budget.overTen)
    Budget+=" - Over 10k . " ; 
    if (this.state.form.personalInfo.name!==''&&this.state.form.personalInfo.email!==''){
    emailjs.send("service_g5j381e","template_snyojel",
    {
      from_name :this.state.form.personalInfo.name ,
      email :this.state.form.personalInfo.email , 
      phoneNumber :this.state.form.personalInfo.phone , 
      date :this.state.form.personalInfo.date , 
      location :this.state.form.personalInfo.location ,
      hours:Hours,
      service : Service,
      budget : Budget,
      message : this.state.form.message 
    },"user_ugT56jC87ZlVLVgNP1wol")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      alert("Your Message Has Been Sent . ") ; 
      this.props.outContacts();

      //reset all the fields
      this.setState(this.form);
      document.getElementById('form').reset();
    }
    else {
    console.log("Missing");
    alert("Name and Email are required .")
    }

  }
  CancelForm = e=>
  {
    this.props.outContacts();

    //reset all the fields
    this.setState(this.form);
    document.getElementById('form').reset();
  }

  render() {
    
    const {
      sixOrLessHours,
      eightHours,
      tenHours,
      twelveOrMoreHours
    } = this.state.form.hours;
    const { photography, videography, photoBooth } = this.state.form.service;
    const {
      underThree,
      threeToSix,
      sixToTen,
      overTen,
      multiDayWedding
    } = this.state.form.budget;

    const ContactsPage = (
      <div>
        <Button onClick={this.handleClickOpen}>info@Diamonddigital.ca</Button>
        <Dialog
          open={this.props.contactsState}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          maxWidth='md'
        >
          <DialogContent>
            <DialogContentText
              style={{ color: '#2b2b2b', fontSize: '1.2em', marginTop: '20px' }}
              id='alert-dialog-slide-description'
            >
              Digital Diamond family would love to hear from you:
            </DialogContentText>
            <form id='form' noValidate autoComplete='off'  onSubmit={this.sendEmail}>
              {/*text fields */}
              <span>
                <TextField
                  name='from_name'
                  label='Name'
                  onChange={this.handleChange('name')}
                  margin='normal'
                  fullWidth
                  required
                />
                <TextField
                  name='email'
                  label='Email'
                  onChange={this.handleChange('email')}
                  margin='normal'
                  fullWidth
                  required 
                />
                <TextField
                  
                  label='Phone Number'
                  onChange={this.handleChange('phone')}
                  margin='normal'
                  fullWidth
                />
                <TextField
                  name='eventDate'
                  label='Wedding or event date'
                  onChange={this.handleChange('date')}
                  margin='normal'
                  fullWidth
                />
                <TextField
                  name='itWillBeAt'
                  label='It will  be at'
                  onChange={this.handleChange('location')}
                  margin='normal'
                  fullWidth
                />
              </span>
              <br />
              <br />
              {/* Check box */}
              <span>
                <FormControl component='fieldset'>
                  <FormLabel
                    style={{ color: '#2b2b2b', fontSize: '1.2em' }}
                    component='legend'
                  >
                    I need you for approximately this many hours
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sixOrLessHours}
                          onChange={this.handleChangeHours('sixOrLessHours')}
                        />
                      }
                      label='6 hours or less (please specify in message)'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={eightHours}
                          onChange={this.handleChangeHours('eightHours')}
                        />
                      }
                      label='8 hours'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tenHours}
                          onChange={this.handleChangeHours('tenHours')}
                        />
                      }
                      label='10 hours'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={twelveOrMoreHours}
                          onChange={this.handleChangeHours('twelveOrMoreHours')}
                        />
                      }
                      label='12 hours or more (please specify in message)'
                    />
                  </FormGroup>
                </FormControl>
              </span>
              <br />
              <br />
              <span>
                <FormControl component='fieldset'>
                  <FormLabel
                    style={{ color: '#2b2b2b', fontSize: '1.2em' }}
                    component='legend'
                  >
                    I'm looking for
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={photography}
                          onChange={this.handleChangeService('photography')}
                        />
                      }
                      label='Photography'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={videography}
                          onChange={this.handleChangeService('videography')}
                        />
                      }
                      label='Videography / cinematography'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={photography && videography ? true : false}
                          onChange={event => {
                            this.handleChangeService('photography');
                            this.handleChangeService('videography');
                          }}
                        />
                      }
                      label='Both photography and videography (discount may apply)'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={photoBooth}
                          onChange={this.handleChangeService('photoBooth')}
                        />
                      }
                      label='Photo Booth'
                    />
                  </FormGroup>
                </FormControl>
              </span>
              <br />
              <br />
              <span>
                <FormControl component='fieldset'>
                  <FormLabel
                    style={{ color: '#2b2b2b', fontSize: '1.2em' }}
                    component='legend'
                  >
                    Budget approximation
                  </FormLabel>

                  <FormHelperText
                    style={{ fontSize: '1em' }}
                    component='legend'
                  >
                    (We may recommend a specific package or a partner company
                    based on your budget. Rates are calculated based on the
                    hours and services requested, and are not influenced by your
                    budget.
                  </FormHelperText>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={underThree}
                          onChange={this.handleChangeBudget('underThree')}
                        />
                      }
                      label='Under 3k'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={threeToSix}
                          onChange={this.handleChangeBudget('threeToSix')}
                        />
                      }
                      label='3k - 6k'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sixToTen}
                          onChange={this.handleChangeBudget('sixToTen')}
                        />
                      }
                      label='6k - 10k'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={overTen}
                          onChange={this.handleChangeBudget('overTen')}
                        />
                      }
                      label='Over 10k'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multiDayWedding}
                          onChange={this.handleChangeBudget('multiDayWedding')}
                        />
                      }
                      label='Multi-day wedding'
                    />
                  </FormGroup>
                </FormControl>
              </span>

              <span>
                <TextField
                  name='name'
                  label='Message'
                  onChange={this.handleChangeMessage('message')}
                  margin='normal'
                  multiline
                  rows='8'
                  fullWidth
                  style={{ color: '#2b2b2b', fontSize: '1.2em' }}
                />
              </span>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              component='span'
              onClick={this.sendEmail}
              style={{ background: '#2b2b2b', color: 'white' }}
            >
              Submit
            </Button>
            <Button
              
              variant='contained'
              component='span'
              onClick={this.CancelForm}
              style={{ background: '#2b2b2b', color: 'white' }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.mailSent && alert('Thank you for contcting us')}
      </div>
    );

    return ContactsPage;
  }
}
