import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PhotoBottom1 from '../../Videos/ShortVideo3.mp4';
import Center from 'react-center';
import './styles.scss';

export default class GalleryLayoutTwoOne extends React.Component {
  render() {

      const GalleryLayoutTwoOne =

            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}> 

                <Grid item xs={6} onClick={this.props.toPhotos} >
                  <Paper className="OneByOne1"> </Paper>
                </Grid>
                <Grid item xs={6} onClick={this.props.toPhotos}>
                  <Paper className="OneByOne2"> </Paper>
                </Grid>
                <Grid item xs={12} onClick={this.props.toVideos}>
                  <Paper className="TwoByTwo2" >
                  <Center style={{ width: '100%',height: '100%',overFlow:'hidden', background: 'red'}}>
                    <video className="TwoOneVideo" src={PhotoBottom1}  style={{objectFit: 'cover',height:"100%"}} muted loop autoPlay playsInline type="video/mp4"></video>
                    </Center>
                  </Paper>
                </Grid>

              </Grid>
            </Grid>;


    return GalleryLayoutTwoOne;
  }
}
