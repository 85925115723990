import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PhotoTop1 from '../../Videos/ShortVideo2.mp4';
import Center from 'react-center';

export default class GalleryLayoutOneTwo extends React.Component {
  render() {
    const GalleryLayoutOneTwo =

    <Grid item xs={12} sm={6} onClick={this.ToPhotos}>
      <Grid container spacing={3}> 
          <Grid item xs={12} onClick={this.props.toVideos}>
          <Paper className="TwoByTwo" >
          <Center style={{
                      width: '100%',
                      height: '100%',
                      overFlow: 'hidden'                        
            }}>
            <video className="OneTwoVideo" src={PhotoTop1} style={{objectFit: 'cover',height:"100%"}} muted loop autoPlay playsInline type="video/mp4"></video>
            </Center>
          </Paper>
        </Grid> 
        <Grid item xs={6} onClick={this.props.toPhotos}>
          <Paper className="OneByOne3" > </Paper>
        </Grid>
        <Grid item xs={6} onClick={this.props.toPhotos}>
          <Paper className="OneByOne4"> </Paper>
        </Grid>
      </Grid>
    </Grid>;

    return GalleryLayoutOneTwo;
  }
}
