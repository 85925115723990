import React from 'react';
import GridContainer from '../GridContainer';
import './styles.scss';


export default class GridContainerBackground extends React.Component {
      render() {

        const GridContainerBackground =
            <div className="GridnContainerBackground">
                <GridContainer 
                toPhotos={this.props.toPhotos}
                toPhotos2={this.props.toPhotos2}
                toVideos={this.props.toVideos}
                outContacts={this.props.outContacts}
                toContacts={this.props.toContacts}
                contactsState={this.props.contactsState}
                />
            </div>;
        return GridContainerBackground;

    }
}