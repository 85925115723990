import React from 'react';
import Home from './sections/Home';
import SideButton from './components/SideButtons';
import VideosPage from './sections/Videos';
import Services from './sections/Services';
import Photos from './sections/Photos';
import Photos2 from './sections/Photos2';



class App extends React.Component {

  constructor () {
    super();
    this.state = {
      contactsState: false,
      loaded: false,
      step: 1,
      styles: {
        zIndex: 1000,
        marginLeft: "600px"
      }
    }
  }
  componentDidMount() {
    this.setState({loaded: true});
  }

  toHome() {
    //to access the state inside the function and make it passable to the children
    this.setState({
        step: 1
    });
  }

  toPhotos() {
    //to access the state inside the function and make it passable to the children
    this.setState({
        step: 2
    });
  }

  toPhotos2() {
    //to access the state inside the function and make it passable to the children
    this.setState({
      step: 6
    });
  }

  toVideos() {
    //to access the state inside the function and make it passable to the children
    this.setState({
      step: 3
    });
  }

  toServices() {
    //to access the state inside the function and make it passable to the children
    this.setState({
      step: 4
    });
  }

  toContacts() {
    //to access the state inside the function and make it passable to the children
    this.setState({
      step: 1
    });

    this.setState({ contactsState: true });
  }
  outContacts() {
    //to access the state inside the function and make it passable to the children
    this.setState({ contactsState: false });
  }

  render() {
    const { step } = this.state;
        switch(step) {
            case 1:
              return (
                <div>
                  <SideButton
                  toPhotos={this.toPhotos.bind(this)}
                  toPhotos2={this.toPhotos2.bind(this)}
                  toVideos={this.toVideos.bind(this)}
                  toHome={this.toHome.bind(this)}
                  toServices={this.toServices.bind(this)}
                  toContacts={this.toContacts.bind(this)}
                  />
                  <Home
                  toPhotos={this.toPhotos.bind(this)}
                  toPhotos2={this.toPhotos2.bind(this)}
                  toVideos={this.toVideos.bind(this)}
                  toHome={this.toHome.bind(this)}
                  toServices={this.toServices.bind(this)}
                  toContacts={this.toContacts.bind(this)}
                  step={this.state.step}
                  contactsState={this.state.contactsState}
                  outContacts={this.outContacts.bind(this)}
                  />
                </div>
               )
            case 2:
              return (
                <div>
                  <SideButton
                  toPhotos={this.toPhotos.bind(this)}
                  toPhotos2={this.toPhotos2.bind(this)}
                  toVideos={this.toVideos.bind(this)}
                  toHome={this.toHome.bind(this)}
                  toServices={this.toServices.bind(this)}
                  toContacts={this.toContacts.bind(this)}
                  />
                  <Photos
                  
                  />
                </div>
               )
            case 3:
              return (
                <div>
                  <SideButton
                  toPhotos={this.toPhotos.bind(this)}
                  toPhotos2={this.toPhotos2.bind(this)}
                  toVideos={this.toVideos.bind(this)}
                  toHome={this.toHome.bind(this)}
                  toServices={this.toServices.bind(this)}
                  toContacts={this.toContacts.bind(this)}
                  
                  />
                  <VideosPage/>
                </div>
               )
            case 4:
              return (
                <div>
                  <SideButton
                  toPhotos={this.toPhotos.bind(this)}
                  toPhotos2={this.toPhotos2.bind(this)}
                  toVideos={this.toVideos.bind(this)}
                  toHome={this.toHome.bind(this)}
                  toServices={this.toServices.bind(this)}
                  toContacts={this.toContacts.bind(this)}                 
                  />
                  <Services/>
                </div>
              )
            case 5:
            return (
              <div>
                <SideButton
                  toPhotos={this.toPhotos.bind(this)}
                  toPhotos2={this.toPhotos2.bind(this)}
                  toVideos={this.toVideos.bind(this)}
                  toHome={this.toHome.bind(this)}
                  toServices={this.toServices.bind(this)}
                  toContacts={this.toContacts.bind(this)}                
                />
                <h1>Contacts Page</h1>
              </div>
             )
             case 6:
             return (
               <div>
                 <SideButton
                 toPhotos={this.toPhotos.bind(this)}
                 toPhotos2={this.toPhotos2.bind(this)}
                 toVideos={this.toVideos.bind(this)}
                 toHome={this.toHome.bind(this)}
                 toServices={this.toServices.bind(this)}
                 toContacts={this.toContacts.bind(this)}
                 
                 />
                 <Photos2/>
               </div>
              )
            default:
             // do nothing
        }
  }
}

export default App;
