import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Logo from '../../components/Logo';
import './styles.scss';

export default class VideosPage extends React.Component {
    render() {
      const WeddingVideos = [
        "336334660",
        "337430012",
        "336333595",
        "336334324",
        "336338557",
        "336335716",
        "336335115",
        "337781588",
        "337783451",
        "337882834",
        "337792417",
        "337785107",
        "197328390",
        "171599655",
        "157438311",
        "152322685",
        "150116020",
        "161076607",
        "183693063",
        "139648891",
        "144212785",
        "102732022",
        "112457345",
        "87198574",
        "81199911",
        "188849933",
        "200363252",
        "463265037",
        "407802956",
        "376902567",
        "438984425",
        "466969677"

    ]

    const WeddingVideos2VideosGrid = [
      "407806010",
      "429802496"

    ]

    const LoveVideos = [
      "337783451",
      "336337772",
      "72241717",
      "49045206"
    ]

    const Commercial = [
      "445863843",
      "470201639",
      "470188486"
    ]

      const VideosArray = []
      for (const [index, value] of WeddingVideos.entries()) {
        VideosArray.push(
        <div className="VideosStyle" id={index}>
          <Vimeo key={index} showByline={true} showPortrait={false} video={value} showTitle={false} color={"FF0000"} responsive={true} />
        </div>)
      }
      const VideosArray2 = []
      for (const [index, value] of WeddingVideos2VideosGrid.entries()) {
        VideosArray2.push(
        <div className="VideosStyle2VideosGrid" id={index}>
          <Vimeo key={index} showByline={false} showPortrait={false} video={value} showTitle={false} color={"FF0000"} responsive={true}/>
        </div>)
      }
      const VideosArray3 =[]
      for (const [index, value] of LoveVideos.entries()) {
        VideosArray3.push(
        <div className="VideosStyle" id={index + 1}>
          <Vimeo key={index} showByline={false} showPortrait={false} video={value} showTitle={false} color={"FF0000"}  responsive={true}/>
        </div>)
      }
      const VideosArray4 =[]
      for (const [index, value] of Commercial.entries()) {
        VideosArray4.push(
        <div className="VideosStyle3VideosGrid" id={index + 1}>
          <Vimeo key={index} showByline={false} showPortrait={false} video={value} showTitle={false} color={"FF0000"} responsive={true}/>
        </div>)
      }
        return (
            <div>
              <div className="LogoInVideosPage"><Logo /></div>
              <div className="VideosStyleContainer">
                  {VideosArray}
                  {VideosArray2}
                  {VideosArray3}
                  {VideosArray4}
              </div>
            </div>
        );
      }
}