import React from 'react';
import RedDot from "../RedDot";
import './styles.scss';
import { Controller, Scene } from 'react-scrollmagic';


export default class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      addClass1: false,
      addClass2: true,
      testoNumber: 1
     };
}
rightArrow() {
  const {testoNumber} = this.state;
  this.setState({testoNumber: testoNumber+1});
};
leftArrow() {
  const { testoNumber } = this.state;
  if (testoNumber === 1) {
    this.setState({testoNumber: 9});
  } else {
    this.setState({testoNumber: testoNumber-1});
  }
};

renderTesto(testoNumber) {
  switch(testoNumber) {
    case 1:
      return (              
        <div className="content1">
          <div className="Profile">
            <div className="ProfilePic"></div>
          </div>
          <div className="ProfileComment">
          <div>Highly recommend Diamond Digital Production services for any event! They do not hesitate to make sure each one of their couples feel comfortable on their big day. With their attention to detail, they did an amazing job capturing our big day and definitely helped us feel like we were re-living the moment. Thanks again Diamond digital!<RedDot /></div>
          <div className="ProfileName"> @Janan Borhot</div>
          </div>
        </div>
        );
    case 2:
      return (
        <div className="content2">
          <div className="Profile">
            <div className="ProfilePic"></div>
          </div>
          <div className="ProfileComment">
          <div>I recommend this team for any event, did an amazing job at my wedding with my pictures and video, there were very nice people who toke there time to ask what I liked as a pose and music for video. Was very impressed and I'm sure to choose them for another event and recommend them to my family and friends. Thank you all for your amazing job!
            <RedDot />
            <div className="ProfileName"> @Narani</div>
            </div>
          
          </div>
        </div>
      );
    case 3:
    return (
      <div className="content3">
        <div className="Profile">
          <div className="ProfilePic"></div>
        </div>
        <div className="ProfileComment">
        <div>We would definitely recommend this reputable team to highlight your special occasion through photography and videography! We appreciated the production quality and their attention to detail with every shot during our engagement shoot. They made the session comfortable, fun and natural. We can’t wait for their efforts to be recognized on our wedding day! Thank you!
          <RedDot />
          <div className="ProfileName"> @Youssef Elkadri</div>
          </div>
        
        </div>
      </div>
    );
    case 4:
    return (
      <div className="content4">
        <div className="Profile">
          <div className="ProfilePic"></div>
        </div>
        <div className="ProfileComment">
        <div>Absolutely reccommend this team/company! For any event! Amazing service have a great eye for detail, scenery and overall outlook for the photo! Completely easy going and meet all my needs without hesitations! Feel very confident knowing they will be capturing my wedding! Can't wait! Thankyou guys!
          <RedDot />
          <div className="ProfileName"> @Urslla Henesly</div>
          </div>
        
        </div>
      </div>
    );
    case 5:
    return (
      <div className="content5">
        <div className="Profile">
          <div className="ProfilePic"></div>
        </div>
        <div className="ProfileComment">
          <div>Thank you so much <span role="img" aria-label="Red Heart">❤️</span> we are amazed with your work. There are no words to describe how happy and thankful we are. Thank you Huda and Ahmed. Can’t wait for the tape <span role="img" aria-label="Smiling Face With Heart-Eyes">😍</span>
            <RedDot />
            <div className="ProfileName"> @Ammanda Mcleod</div>
          </div>
        </div>
      </div>
    );
    case 6:
    return (
      <div className="content6">
        <div className="Profile">
          <div className="ProfilePic"></div>
        </div>
        <div className="ProfileComment">
        <div>Your photos are STUNNING! Thank you so, so much. They are all just spectacular and a gift we will treasure always. We are really grateful. Just a talented photographer. We can't thank you enough for sharing your craft with us. Thank you!
          <RedDot />
          <div className="ProfileName"> @Scott James</div>
          </div>
        
        </div>
      </div>
    );
    case 7:
      return (
        <div className="content7">
          <div className="Profile">
            <div className="ProfilePic"></div>
          </div>
          <div className="ProfileComment">
          <div>I am beyond impressed with how my engagement shoot turned out. Ahmad & Huda were amazing and such a sweet couple. They made us feel very comfortable and our pictures turned out incredible. Cannot wait for them to do my wedding photos & video. 10/10 would recommend
            <RedDot />
            <div className="ProfileName"> @Sarah Ek </div>
            </div>
          </div>
        </div>
      );
    case 8:
      return (
        <div className="content8">
          <div className="Profile">
            <div className="ProfilePic"></div>
          </div>
          <div className="ProfileComment">
          <div>I choose Diamond Digital Photography for my winter wedding for both videography and photography. The team were so punctual and cooperative. They added to the fun of my wedding day. I was amazed by the photos and videos they took. All in all, I had a great experience with them.
            <RedDot />
            <div className="ProfileName"> @Wan Hsuan Lin</div>
            </div>
          </div>
        </div>
      );
    case 9:
      return (
        <div className="content9">
          <div className="Profile">
            <div className="ProfilePic"></div>
          </div>
          <div className="ProfileComment">
          <div>I would like to thank Ahmad and Huda and the rest of Diamond Digital team. I appreciate all the time, effort, and hard work they put into my son's wedding.You truly did an amazing job. Can't wait to see the rest of the wedding photos and video. I highly recommend them to everyone out there.
            <RedDot />
            <div className="ProfileName"> @KamLee Zabian</div>
            </div>
          </div>
        </div>
      );
    default:
      this.setState({testoNumber: 1});
  }
}
  render() {

           const { testoNumber } = this.state;
          const Testimonial =

          <div className="Testimonial" name="Testimonial">

              <Controller >
                <Scene duration={400} classToggle="active" triggerElement=".Testimonial">
                  <div className="LeftArrow">
                    <div  onClick={this.leftArrow.bind(this)}></div>
                  </div>
                </Scene>          
              </Controller>

              {this.renderTesto(testoNumber)}

              <Controller >
                <Scene duration={400} classToggle="active" triggerElement=".Testimonial">
                  <div className="RightArrow">
                    <div  onClick={this.rightArrow.bind(this)}></div>
                  </div>
                </Scene>          
              </Controller>
              
          </div>

    return Testimonial;
  }
}
