import React, {Component} from 'react';
import './styles.scss';
import TextLoop from "react-text-loop";
import VideoBackground from './../../Videos/TrailerWebsite2.mp4';

export default class Example extends Component {
    constructor (props) {
        super(props);

        this.state = {
            VideoUrl: VideoBackground,
            pip: false,
            playing: true,
            controls: false,
            light: false,
            played: 0.20,
            loaded: 0.20,
            loop: true,
            wrapper: "div",
            imageStatus: "loading"
          }   
    }

    handleImageLoaded() {
        this.setState({ imageStatus: "loaded" });
    }
    
    handleImageErrored() {
    this.setState({ imageStatus: "failed to load" });
    }
    render () {

        const BackgroundVideo =
        <div className='BackgroundVideoSection'>

            <div className="VideoTitle">Start  
                <TextLoop interval={3000} adjustingSpeed={350}>
                    <span>a holy matrimony</span>
                    <span>a Special Day</span>
                    <span>an Engagement party</span>
                </TextLoop>{" "}
                 that will <br></br>be rememebred with love endlessly.
            
                <div className="Logo"></div>
            </div>
    
            <div className="BackgroundVideo">
                <div className="DarkMask"></div>
                <video src={VideoBackground} style={{objectFit: 'cover',height:"100%"}} muted loop autoPlay playsInline type="video/mp4"></video>
            </div>
            <div className="Arrows">
                    <div className="arrow arrow-1"></div>
                    <div className="arrow arrow-2"></div>
            </div>

        </div>
        return BackgroundVideo;            
    }
};
