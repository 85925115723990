import React from 'react';
import './styles.scss';
import { Link } from "react-scroll";



export default class SideButton extends React.Component {

  constructor(props) {
    super(props);
    this.MobileMenuButton= this.MobileMenuButton.bind(this);
    this.ToHome= this.ToHome.bind(this);
    this.ToPhotos= this.ToPhotos.bind(this);
    this.ToPhotos2= this.ToPhotos2.bind(this);
    this.ToVideos= this.ToVideos.bind(this);
    this.ToServices= this.ToServices.bind(this);
    this.ToContacts= this.ToContacts.bind(this);
    this.state = {
        active: false,
    };
}

 MobileMenuButton(){
   const currentState = this.state.active;
   this.setState({ active: !currentState });
 }

 ToHome(){
  this.props.toHome();
  this.MobileMenuButton();
}
ToPhotos(){
  this.props.toPhotos();
  this.MobileMenuButton();
}

ToPhotos2(){
  this.props.toPhotos2();
  this.MobileMenuButton();
}

ToVideos(){
  this.props.toVideos();
  this.MobileMenuButton();
}

ToServices(){
  this.props.toServices();
  this.MobileMenuButton();
}

ToContacts(){
  this.props.toContacts();
  this.MobileMenuButton();
}
  render() {
      const SideButton =
      //mobile menu
        <div>
          <div  className={this.state.active ? 'MobileMenuButtenActive': "MobileMenuButten"} onClick={this.MobileMenuButton}>
            <div></div> 
            <div></div>
            <div></div>
          </div>
          <ol className={this.state.active ? 'MobileMenu': "MobileMenuActive"}>
            <li><div onClick={this.ToHome} >Home</div></li>
            <li><div onClick={this.ToPhotos}>Weddings</div></li>
            <li><div onClick={this.ToPhotos2}>Engagment</div></li>
            <li><div onClick={this.ToVideos}>Videos</div></li>
            <li><div onClick={this.ToServices}>Services</div></li>
            <li><div onClick={this.ToContacts}>Contacts</div></li>
          </ol>

        {/* Desktop Menu */}
        <div className="SideBar">
          <div className="ButtonsContainer">
              
              <ul>
                <li className="HomeButton"><span onClick={this.props.toHome} className="ButtonStyle">Home</span></li>
                <li><span onClick={this.props.toPhotos} className="ButtonStyle">Weddings</span></li>
                <br></br><br></br>
                <li><span onClick={this.props.toPhotos2} className="ButtonStyle">Engagment</span></li>
                <br></br><br></br><br></br>
                <li><span onClick={this.props.toVideos} className="ButtonStyle">Videos</span></li>
                <li><span onClick={this.props.toServices} className="ButtonStyle" >Services</span></li>
                <br></br>
                <li><Link smooth={true} to="Contacts" spy={true} onClick={this.props.toContacts} className="ButtonStyle">Contacts</Link></li>
              </ul>
          </div>
        </div>
      </div>
    return SideButton;
  }
}
