import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GalleryLayoutTwoOne from "../GalleryLayoutTwoOne";
import GalleryLayoutOneTwo from "../GalleryLayoutOneTwo";
import RedDot from "../RedDot";
import LongVideo from '../../Videos/ShortVideo4.mp4';
import Best2019 from '../../Videos/ShortVideo5.mp4';
import Testimonial from '../Testemonial';
import ContactsPage from '../../sections/ContactsPage';
import Center from 'react-center';
import './GridStyles.scss';
import { Link } from "react-scroll";

export default class GridLayout extends React.Component {
  render() {
      const GridLayout =
        <div > 
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="BusinessDescription" name="BusinessDescription" >
              <div>We are one of the longest established wedding video filming and Photography companies in the London. We believe you have found the right company to produce a memorable wedding film for you, that it will be the best reminder of your day and will become one of your most cherished possessions that you own in your entire lifetime
              <RedDot />
              </div>
              <Link activeClass="Lineactive" smooth={true} to="BusinessDescription" spy={true} offset={-450} >
                <div className="Line" >
                  <div className="LineBody" ></div>
                  <div className="LineCircle"></div>
                </div>
              </Link>
              </div>
            </Grid>

            <GalleryLayoutTwoOne toPhotos={this.props.toPhotos} toVideos={this.props.toVideos}/>
            <GalleryLayoutOneTwo toPhotos={this.props.toPhotos} toVideos={this.props.toVideos}/>

            <Grid item xs={12} >
                  <Paper className="TopWeddingVideo" name="TopWeddingVideo" >
                  <Link  onClick={this.props.toVideos} to="TopWedding">
                      <div className="TopWedding">
                        <div>
                          <p> Best Wedding 2018 </p>
                        </div>
                      </div>
                      <Center style={{
                                width: '100%',height: '800px',overflow: 'hidden', background: 'red'               
                      }}>
                      <video src={Best2019}style={{objectFit: 'cover',height:"100%", width:"100%"}} muted loop autoPlay playsInline type="video/mp4" ></video>
                      </Center>
                      
                    </Link>
                  </Paper>
            </Grid>

            {/* Left column below the video */}
            <Grid item xs={12} sm={6}>
              <Grid container direction="column" spacing={3}> 

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}> 
                  <Grid item xs={6} onClick={this.props.toPhotos}>
                    <Paper className="OneByOne5" > </Paper>
                  </Grid>
                  <Grid item xs={6} onClick={this.props.toPhotos}>
                    <Paper className="OneByOne6"> </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div name="Services" className="Services">
                      <h2>Capturing your moments through our lenses</h2>
                      
                      <div>
                        <div className="ServicesStyling">
                            <Link className="Line1" activeClass="ServicesLineActive" smooth={true} to="Services" spy={true} offset={-650}>
                                <div className="LineBody" ></div>
                                <div className="LineCircle"></div>  
                            </Link>                            
                        </div> 
                        
                        <div className="ServicesContent">
                        <h4>Our team of experienced photographers and videographers will capture all the best moments of your weddings so that when you look back and watch it, you will feel the same emotions when you spoke your vows, heard the heartfelt speeches and danced together to your song.
                        We are incredibly passionate about our work and the opportunity to capture epic love stories never fails to excite us!
                        </h4>

                        </div>
                        
                      </div>
                      
                    </div>

                  </Grid> 
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}> 
                  <Grid item xs={6} onClick={this.props.toPhotos}>
                    <Paper className="OneByOne7"> </Paper>
                  </Grid>
                  <Grid item xs={6} onClick={this.props.toPhotos}>
                    <Paper className="OneByOne8"> </Paper>
                  </Grid>
                  <Grid item xs={12} onClick={this.props.toPhotos}>
                    <Paper className="TwoByTwo1"></Paper>
                  </Grid> 
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div name="Media" className="Media">
                  <div className="LineContainer">
                  <Link className="Line5" activeClass="Line5Active" smooth={true} to="Media" spy={true} offset={-500}>
                    <div className="LineBody" ></div>
                    <div className="LineCircle"></div>  
                  </Link>
                  </div>
                  <div className="SocialMedia"><p>Social Media</p></div>
                  <div className="Icons">
                    <a className="FB HoverAnimation"  href="https://www.facebook.com/DiamondDigitalProduction/"><span></span></a>
                    <a className="IG HoverAnimation"  href="https://www.instagram.com/diamonddigitalproduction/"><span></span></a>
                    <a className="Vimeo HoverAnimation"  href="https://vimeo.com/user1214073"><span></span></a>
                    <a className="YouTube HoverAnimation"  href="https://www.youtube.com/user/ahmad241"><span></span></a>
                  </div>
                </div>
              </Grid> 

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}> 
                  <Grid item xs={6} onClick={this.props.toPhotos2}>
                    <Paper className="OneByOne9"> </Paper>
                  </Grid>
                  <Grid item xs={6} onClick={this.props.toPhotos2}>
                    <Paper className="OneByOne10"> </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div name="Contacts" className="Contacts">
                      <h1>Contacts</h1>
                      <div className="ContactsContent">

                         <div className="ContactsStyling">
                            <Link className="Line1" activeClass="ServicesLineActive" smooth={true} to="Contacts" spy={true} offset={-700}>
                                <div className="LineBody" ></div>
                                <div className="LineCircle"></div>  
                            </Link>
                            <Link className="Line2" activeClass="ServicesLineActive" smooth={true} to="Contacts" spy={true} offset={-650}>
                                <div className="LineBody" ></div>
                                <div className="LineCircle"></div>  
                            </Link>
                            <Link className="Line3" activeClass="ServicesLineActive" smooth={true} to="Contacts" spy={true} offset={-600}>
                                <div className="LineBody" ></div>
                                <div className="LineCircle"></div>  
                            </Link>

                          </div>

                          <div className="ContactsDetails">
                            <div>(519) 701 5201</div>
                            <div>< ContactsPage
                            outContacts={this.props.outContacts}
                            toContacts={this.props.toContacts}
                            contactsState={this.props.contactsState}
                            />
                            </div>
                            <div>9:00am - 9:00pm</div>
                          </div>
                          
                      </div>
                    </div>
                  </Grid> 
                </Grid>
              </Grid>

              </Grid>
            </Grid>

            {/* Right column below the video */}
            <Grid item xs={12} sm={6}>
              <Grid container direction="column" spacing={3}> 

                <Grid  item xs={12} >
                  <div name="RedCorners"className="RedCorners">
                    <div className="RedCorneresPicture"></div>
                    <Link activeClass="active" smooth={true} to="RedCorners" spy={true} offset={-450}>
                      <div className="RedCornersInner" onClick={this.props.toPhotos}>
                        <div className="FrameTopLeft"></div>
                        <div className="FrameTopRight"></div>
                        <div className="FrameBottomRight"></div>
                        <div className="FrameBottomLeft"></div>
                      </div>
                    </Link>
                  </div>
                </Grid> 

                <Grid item xs={12}>
                  <Testimonial /> 
                </Grid>

                <Grid item xs={12} onClick={this.props.toVideos}>
                  <Paper className="LongPic">
                      <div className="LongPicRecordingDot"></div>
                      <div className="LongPicContent">
                      <Center style={{
                                width: '100%',height: '100%',overFlow: 'hidden', background: 'red', overflow:"hidden"            
                      }}>
                        <video className="RedDoteVideo" src={LongVideo} style={{objectFit: 'cover',height:"100%"}} muted loop autoPlay playsInline type="video/mp4"></video>
                        </Center>
                    </div>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}> 
                    <Grid item xs={12} onClick={this.props.toPhotos}>
                      <Paper className="TwoByTwo2"></Paper>
                    </Grid>
                    <Grid item xs={6} onClick={this.props.toPhotos}>
                      <Paper className="OneByOne11"> </Paper>
                    </Grid>
                    <Grid item xs={6} onClick={this.props.toPhotos}>
                      <Paper className="OneByOne12"> </Paper>
                    </Grid>
                  </Grid>
               </Grid>

              </Grid>
            </Grid>
            
            <Grid item xs={12}>
              <p className="Footer"> “Photography for me is not looking, it’s feeling. If you can’t feel what you’re looking at, then you’re never going to get others to feel anything when they look at your pictures.”
– Don McCullin</p>
                

            </Grid>

          </Grid>
        </div>;

    return GridLayout;
  }
}
