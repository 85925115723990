import React from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import './styles.scss';

const photos = [
    { src: require('../../Images/Wedding/01.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/02.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/03.jpg'), width: 6, height: 9 },
    { src: require('../../Images/Wedding/04.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/05.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/06.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/07.jpg'), width: 5, height: 7 },
    { src: require('../../Images/Wedding/08.jpg'), width: 5, height: 7 },
    { src: require('../../Images/Wedding/09.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/10.jpg'), width: 5, height: 7 },
    { src: require('../../Images/Wedding/11.jpg'), width: 7, height: 5 },
    { src: require('../../Images/Wedding/12.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/13.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/14.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/15.jpg'), width: 7, height: 5 },
    { src: require('../../Images/Wedding/16.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/17.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/18.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/19.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/20.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/21.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/22.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/23.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/24.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Wedding/25.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/26.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Wedding/27.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/28.jpg'), width: 5, height: 7 },
    { src: require('../../Images/Wedding/29.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/30.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/31.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Wedding/32.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/33.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/34.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/35.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/36.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/37.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/38.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/39.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/40.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/41.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/42.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/43.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Wedding/44.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Wedding/45.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/46.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/47.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/48.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/49.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/50.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/51.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/52.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Wedding/53.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/54.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/55.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/56.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/57.jpg'), width: 6, height: 9 },
    { src: require('../../Images/Wedding/58.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/59.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/60.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/61.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/62.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/63.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/64.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/65.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/66.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/67.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/68.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/69.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/70.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/71.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/72.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/73.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/74.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/75.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Wedding/76.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Wedding/77.jpg'), width: 5, height: 8 },
  ];

export default class Photos extends React.Component {

    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
      }
      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }

    render() {
        return (
            <div>
                <div className="DarkSideBar"></div>
                <Gallery className="GallaryPhotos" photos={photos} onClick={this.openLightbox} />
                <Lightbox views={photos}
                  onClose={this.closeLightbox}
                  onClickPrev={this.gotoPrevious}
                  onClickNext={this.gotoNext}
                  currentImage={this.state.currentImage}
                  isOpen={this.state.lightboxIsOpen}
                />
            </div>
        );
      }
}