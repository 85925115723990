import React from 'react';
import './styles.scss';

export default class Logo extends React.Component {
  render() {
      var LogoStyle = {
        right:'0px'
      }
      const Logo =
          <div className="LogoStyle" style={LogoStyle}></div>
    return Logo;
  }
}
