import React from 'react';
import './styles.scss';
import Grid from '../Grid';


export default class GridContainer extends React.Component {
      render() {
        const GridContainer=
          <div className="GridContainer">
            <div className="WhiteFade"></div>
            <Grid
              toPhotos={this.props.toPhotos}
              toPhotos2={this.props.toPhotos2}
              toVideos={this.props.toVideos}
              outContacts={this.props.outContacts}
              toContacts={this.props.toContacts}
              contactsState={this.props.contactsState}
            />
          </div>;
        return GridContainer;

    }
}