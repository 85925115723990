import React from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import './styles.scss';

const photos = [
    { src: require('../../Images/Engagment/01.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/02.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/03.jpg'), width: 6, height: 9 },
    { src: require('../../Images/Engagment/04.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/05.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/06.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/07.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Engagment/08.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Engagment/09.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/10.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Engagment/11.jpg'), width: 7, height: 5 },
    { src: require('../../Images/Engagment/12.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/13.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/14.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/15.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/16.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Engagment/17.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/18.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/19.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/20.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/21.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/22.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/23.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/24.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/25.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/26.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/27.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/28.jpg'), width: 5, height: 7 },
    { src: require('../../Images/Engagment/29.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/30.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/31.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/32.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/33.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/34.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/35.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/36.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/37.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/38.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/39.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/40.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/41.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/42.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/44.jpg'), width: 8, height: 6 },
    { src: require('../../Images/Engagment/45.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/46.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/47.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/48.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/49.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/50.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/51.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/52.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/53.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/54.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/55.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/56.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/57.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/58.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/59.jpg'), width: 5, height: 8},
    { src: require('../../Images/Engagment/60.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/61.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/62.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/63.jpg'), width: 8, height: 5 },
    { src: require('../../Images/Engagment/64.jpg'), width: 5, height: 8 },
    { src: require('../../Images/Engagment/65.jpg'), width: 5, height: 8 }
  ];

export default class Photos2 extends React.Component {

    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
      }
      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }

    render() {
        return (
            <div>
                <div className="DarkSideBar"></div>
                <Gallery className="GallaryPhotos" photos={photos} onClick={this.openLightbox} />
                <Lightbox views={photos}
                  onClose={this.closeLightbox}
                  onClickPrev={this.gotoPrevious}
                  onClickNext={this.gotoNext}
                  currentImage={this.state.currentImage}
                  isOpen={this.state.lightboxIsOpen}
                />
            </div>
        );
      }
}