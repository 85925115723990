import React, { Fragment } from 'react';
import './styles.scss';

export default class RedDot extends React.Component {
  render() {
      const RedDot =
        <Fragment >
          <div className="RedDot"></div>
        </Fragment>
    return RedDot;
  }
}
